import React from 'react';
import { Footer } from '../Util/Footer';
import json from '../../assets/util/json/Legale.json';

export function PoliConfi() {
  return (
    <>
      <div className="mentionLeg">
        <h1>{json[1].Title}</h1>
        <p>{json[1].Partie1}</p>
        <p>{json[1].Partie2}</p>
        <p>{json[1].Partie3}</p>
        <p>{json[1].Partie4}</p>
        <p>{json[1].Partie5}</p>
        <p>{json[1].Partie6}</p>
        <h2>{json[1].Partie7}</h2>
        <p>{json[1].Partie8}</p>
        <p>{json[1].Partie9}</p>
        <p>{json[1].Partie10}</p>
        <p>{json[1].Partie11}</p>
        <h2>{json[1].Partie12}</h2>
        <h3>{json[1].Partie13}</h3>
        <p>{json[1].Partie14}</p>
        <p>{json[1].Partie15}</p>
        <h2>{json[1].Partie16}</h2>
        <p>{json[1].Partie17}</p>
        <p>{json[1].Partie18}</p>
        <p>{json[1].Partie19}</p>
        <p>{json[1].Partie20}</p>
      </div>
      <Footer />
    </>
  );
}
