import '../../App.scss';
import { Outlet, Link } from 'react-router-dom';
import logo from '../../assets/Logo/logo-transparent-png.png';
import drapeau from '../../assets/util/drapeau/france.png';
import useAuth from '../../hook/useAuth';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { isConnect } from './Login';
import { Bookmark, Home } from '@mui/icons-material';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

export function TopBar() {
  const { setAuth }: any = useAuth();

  const authCheck = isConnect();
  // const [open, setState] = useState(false);

  // const toggleDrawer = (open: boolean) => (event: any) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }
  //   setState(open);
  // };

  const theme = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true
        },
        styleOverrides: {
          root: {
            color: 'black',
            padding: 0,
            textTransform: 'none',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            display: 'flex'
          }
        }
      }
    }
  });

  const logOut = () => {
    setAuth({});
    sessionStorage.removeItem('token');
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <nav className="ContainerTopBar">
          <div className="topbar">
            <div />
            <div className="FlexRow">
              <div className="ContainerLink">
                <Link className="linkStyle" to="/">
                  <img className="logo" src={logo} alt="ImageTopBar"></img>
                </Link>
                <Link className="linkStyle" to="/box">
                  Box
                </Link>
                <Link className="linkStyle" to="/sponcor">
                  Partenaire
                </Link>
                <Link className="linkStyle" to="/contact">
                  Contact
                </Link>
              </div>
            </div>
            <div className="cloud" />
            {authCheck ? (
              <div className="ContainerLog">
                <Link className="linkStyle" to="/profil">
                  Profil
                </Link>
                <Button variant="text" onClick={logOut}>
                  LogOut
                </Button>
                <img className="flag" src={drapeau} alt="Drapeau Francais" />
              </div>
            ) : (
              <div className="ContainerLog">
                <Link className="linkStyle" to="/logIn">
                  Log In
                </Link>
                <Link className="linkStyle" to="/signIn">
                  Sign In
                </Link>
                <img className="flag" src={drapeau} alt="Drapeau Francais" />
              </div>
            )}
          </div>
        </nav>
      </ThemeProvider>
      <div className="topbarMobile">
        {/*  <Button onClick={toggleDrawer(true)}>*/}
        {/*    <MenuIcon />*/}
        {/*  </Button>*/}

        {/*  /!* The outside of the drawer *!/*/}
        {/*  <Drawer*/}
        {/*    anchor="bottom" //from which side the drawer slides in*/}
        {/*    variant="temporary" //if and how easily the drawer can be closed*/}
        {/*    open={open} //if open is true, drawer is shown*/}
        {/*    onClose={toggleDrawer(false)} //function that is called when the drawer should close*/}
        {/*  >*/}
        {/*    <Box sx={{ height: 250 }}>*/}
        {/*      <div className="FlexRow">*/}
        {/*        <div className="ContainerLink">*/}
        {/*          <Link className="linkStyle" to="/">*/}
        {/*            <img className="logo" src={logo} alt="ImageTopBar"></img>*/}
        {/*          </Link>*/}
        {/*          <Link className="linkStyle" to="/box">*/}
        {/*            Box*/}
        {/*          </Link>*/}
        {/*          <Link className="linkStyle" to="/sponcor">*/}
        {/*            Partenaire*/}
        {/*          </Link>*/}
        {/*          <Link className="linkStyle" to="/contact">*/}
        {/*            Contact*/}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*        {authCheck ? (*/}
        {/*          <div className="ContainerLogDrawer">*/}
        {/*            <Link className="linkStyle" to="/profil">*/}
        {/*              Profil*/}
        {/*            </Link>*/}
        {/*            <Button variant="text" onClick={logOut}>*/}
        {/*              LogOut*/}
        {/*            </Button>*/}
        {/*            <img className="flag" src={drapeau} alt="Drapeau Francais" />*/}
        {/*          </div>*/}
        {/*        ) : (*/}
        {/*          <div className="ContainerLogDrawer">*/}
        {/*            <Link className="linkStyle" to="/logIn">*/}
        {/*              Log In*/}
        {/*            </Link>*/}
        {/*            <Link className="linkStyle" to="/signIn">*/}
        {/*              Sign In*/}
        {/*            </Link>*/}
        {/*            <img className="flag" src={drapeau} alt="Drapeau Francais" />*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    </Box>*/}
        {/*  </Drawer>*/}
        <nav>
          <ul>
            <li>
              <div>
                <Link to="/">
                  <Home />
                </Link>
              </div>
              Home
            </li>
            <li>
              <div>
                <Link to="/box">
                  <ImportContactsIcon />
                </Link>
              </div>
              Box
            </li>
            <li>
              <div>
                <Link to="/sponcor">
                  <Diversity1Icon />
                </Link>
              </div>
              Partenaire
            </li>
            <li>
              <div>
                <Link to="/contact">
                  <Bookmark />
                </Link>
              </div>
              Contact
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </div>
  );
}
