import React from 'react';
import '../../App.scss';
import { ImageFondContact } from '../Contact/ImageFondContact';
import { Footer } from '../Util/Footer';
import data from '../../assets/util/json/Contact.json';

export function ContactPage() {
  return (
    <div className="ContainerContact">
      <ImageFondContact />
      <div className="ContactBox">
        <h2>{data.Titre}</h2>
        <div>
          <div className="BoxImage">
            <p className="Textlighter">{data.BoxImageTextlighter}</p>
            <p className="TextBolder">{data.BoxImageTextBolder}</p>
          </div>
          <div className="imgBox" />
          <div className="BoxRight">
            <p className="Textlighter">{data.BoxRightTextlighter}</p>
            <p className="TextBolder">{data.BoxRightTextBolder}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
