import React from 'react';
import '../../App.scss';
import { ImageFondSponcor } from '../Sponcor/ImageFondSponcor';
import { Footer } from '../Util/Footer';

import { ImagePagination } from '../Sponcor/ImagePagination';

export function SponcorPage() {
  return (
    <div className="ContainerSponcor">
      <ImageFondSponcor />
      <ImagePagination />
      <Footer />
    </div>
  );
}
