import React from 'react';
import '../../App.scss';
import { Footer } from '../Util/Footer';

export function MissingPage() {
  return (
    <>
      <div className="BoxContainer">
        <div className="container">
          <div className="BoxText">
            <h2>ERROR 404</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores aspernatur autem,
              culpa deserunt distinctio, doloremque dolores ea eligendi et ex expedita illo impedit
              iure, maiores mollitia nemo quis sequi tenetur.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
