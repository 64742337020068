import React from 'react';
import '../../App.scss';
import { Facebook, Instagram } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { openInNewTab } from '../Util/blank';

export function Social() {
  return (
    <div className="Social">
      <div>
        <IconButton
          onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100093430932906')}>
          <Facebook className="logoSVG" />
          <p>Facebook</p>
        </IconButton>
      </div>
      <div>
        <IconButton onClick={() => openInNewTab('https://www.instagram.com/koreantripbox/')}>
          <Instagram className="logoSVG" />
          <p>Instagram</p>
        </IconButton>
      </div>
    </div>
  );
}
