import React from 'react';
import '../../App.scss';
import { Footer } from '../Util/Footer';
import { LoginForm } from '../Auth/LoginForm';

export function LoginPage() {
  return (
    <div className="ContainerAuth">
      <LoginForm />
      <Footer />
    </div>
  );
}
