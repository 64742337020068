import React from 'react';
import '../../App.scss';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import image1 from '../../assets/image/Image_01.jpg';
import AlertDialogSlide from '../Util/Dialog';
import json from '../../assets/util/json/Dialog.json';

export function Contact() {
  return (
    <div className="ContactHomeBox">
      <img src={image1} alt="ImageContact" />
      <div className="APropos">
        <p>A propos de nous</p>
        <p>KOREANTRIP EST UNE ENTREPRISE FAITE PAR DEUX AMIES.</p>
        <Link
          to="/contact
        ">
          Contact Us
        </Link>
      </div>
      <div className="Newsletter">
        <div>
          <p>Recevez notre newsletter</p>
          <p>Saisissez votre adresse e-mail</p>
        </div>
        <TextField id="outlined-basic" label="Email" variant="outlined" size="small" />
        <AlertDialogSlide Text={json[1].Text} Title={json[1].Title} isOpen={false} />
      </div>
    </div>
  );
}
