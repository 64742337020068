import React from 'react';
import '../../App.scss';

export function ImageFondBox() {
  return (
    <div className="imageFondBox">
      <div className="text">
        <h2>KoreanTrip Box</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque, eaque.</p>
      </div>
    </div>
  );
}
