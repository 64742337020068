import React from 'react';
import '../../App.scss';

export function ImageFondContact() {
  return (
    <div className="imageConctat">
      <h2>A PROPOS</h2>
    </div>
  );
}
