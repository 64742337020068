import React from 'react';
import '../../App.scss';

export function ImageFondSponcor() {
  return (
    <div className="imageSponcor">
      <h2>PARTENAIRES</h2>
    </div>
  );
}
