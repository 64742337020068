import useAuth from '../../hook/useAuth';

export const isConnect = () => {
  // @ts-ignore
  const { auth } = useAuth();
  const token = sessionStorage.getItem('token');

  return (
    (typeof auth == 'string' && auth.split(' ')[0] === 'Bearer') ||
    (typeof token == 'string' && token.split(' ')[0] === 'Bearer')
  );
};
