import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { openInNewTab } from '../Util/blank';

export function PartenaireBox({ data }: any) {
  const [current, setCurrent] = useState(0);
  const [image, setImage] = useState(data.imgSrc[0]);
  const maxImage = data.imgSrc.length;

  setTimeout(() => {
    setCurrent((current + 1) % maxImage);
    setImage(data.imgSrc[current]);
  }, 4000);

  console.log(data.lien);

  return (
    <div>
      <div className="Introduction">
        <h2 className="Title">{data.title}</h2>
        <p className="TextIntro">{data.intro}</p>
      </div>
      <div className="MainContent">
        <img className="ImageContent" src={image} alt={data.imgAlt} />
        <div className="TextBox">
          <p className="Textlighter">{data.textLight}</p>
          <p className="TextBolder">{data.textBold}</p>
          <Button onClick={() => openInNewTab(data.lien)} variant="contained">
            Site {data.title}
          </Button>
        </div>
      </div>
    </div>
  );
}
