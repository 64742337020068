import React from 'react';
import './App.scss';

// React-Router-Dom Import
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Component Import
import { TopBar } from './component/Util/TopBar';
import { SponcorPage } from './component/Main/SponsorPage';
import { BoxPage } from './component/Main/BoxPage';
import { HomePage } from './component/Main/HomePage';
import { ContactPage } from './component/Main/ContactPage';
import { LoginPage } from './component/Main/Login';
import { RegisterPage } from './component/Main/Register';
import { MissingPage } from './component/Main/Missing';
import { RequireAuth } from './component/Auth/RequireAuth';
import { ProfilPage } from './component/Main/ProfilePage';
import { PoliConfi } from './component/Main/PoliConfi';
import { CondiUtil } from './component/Main/CondiUtili';
import { MentionLegale } from './component/Main/MentionLegale';
import { PoliCokiee } from './component/Main/PoliCokiee';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TopBar />}>
          {/* Auth Routes */}
          <Route path="login" element={<LoginPage />} />
          <Route path="signIn" element={<RegisterPage />} />

          {/* Public Routes */}
          <Route index element={<HomePage />} />
          <Route path="box" element={<BoxPage />} />
          <Route path="sponcor" element={<SponcorPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="politiqueConfi" element={<PoliConfi />} />
          <Route path="conditionUtilisation" element={<CondiUtil />} />
          <Route path="mentionLegale" element={<MentionLegale />} />
          <Route path="politiqueCookie" element={<PoliCokiee />} />
          <Route path="*" element={<MissingPage />} />

          {/* Protect Routes Admin*/}
          {/*<Route path="*" element={< />} />*/}

          {/* Protect Routes User */}
          <Route element={<RequireAuth />}>
            <Route path="profil" element={<ProfilPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
