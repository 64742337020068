import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, FormControl, InputLabel, Input } from '@mui/material';
import { PasswordReg, USER_REGEX } from '../../assets/util/RegExp';
import useAuth from '../../hook/useAuth';
import { instance } from '../../config/axios';
import { LoginInter } from '../../Interface/Interface';

export function LoginForm() {
  // @ts-ignore
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      login: '',
      password: ''
    },
    criteriaMode: 'all'
  });

  const onSubmit: SubmitHandler<LoginInter> = (data) => {
    if (data) {
      instance
        .get('/auth/login', {
          params: {
            username: data?.login,
            password: data?.password
          }
        })
        .then(async (res) => {
          sessionStorage.setItem(
            'token',
            JSON.stringify(res.data.token).replace(`"`, ``).slice(0, -1)
          );
          await setAuth(res.data.token);
          navigate('/');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="AuthBox">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Username/Email</InputLabel>
          <Input
            {...register('login', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: USER_REGEX,
                message: 'Regex Error.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="login"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Password</InputLabel>
          <Input
            {...register('password', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: PasswordReg,
                message: 'This input need a letter uppercase and a number.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <Button variant="contained" type="submit">
          Log In
        </Button>
      </form>
    </div>
  );
}
