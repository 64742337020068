import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PartenaireBox } from './PartenaireBox';
import json from '../../assets/util/json/Partenaire.json';
import Button from '@mui/material/Button';

export function ImagePagination() {
  const itemsPerPage = 1;
  const data = json;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = data.slice(startIndex, endIndex);

  function getNextPage() {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(0);
    }
  }

  function getPreviousPage() {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(data.length - 1);
    }
  }

  return (
    <div className="ShowSponcor">
      {subset.map((item: any) => (
        <div className="SponcorFlex" key={item.id}>
          <IconButton className="arrow" size="large" onClick={getPreviousPage}>
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
          <PartenaireBox data={item} />
          <IconButton className="arrow" size="large" onClick={getNextPage}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
          <div className="showBut">
            <Button className="sponcorBut" variant="contained" onClick={getPreviousPage}>
              Previous
            </Button>
            <Button className="sponcorBut" variant="contained" onClick={getNextPage}>
              Next
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
