import React from 'react';
import '../../App.scss';
import { Footer } from '../Util/Footer';
import { Button, FormControl, Input, InputLabel } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MailReg, PasswordReg, USER_REGEX } from '../../assets/util/RegExp';
import { ErrorMessage } from '@hookform/error-message';
import { instance } from '../../config/axios';
import useAuth from '../../hook/useAuth';
import { User } from '../../Interface/Interface';

export function ProfilPage() {
  const { auth }: any = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: '',
      password: '',
      email: ''
    },
    criteriaMode: 'all'
  });

  const onSubmit: SubmitHandler<User> = (data) => {
    console.log('updateUser', data);

    const config = {
      headers: { Authorization: auth }
    };

    const bodyParameters: User = {
      username: data.username !== '' ? data.username : undefined,
      password: data.password !== '' ? data.password : undefined,
      mail: data.mail !== '' ? data.mail : undefined
    };
    if (data) {
      instance
        .put('/users/updateOne', bodyParameters, config)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="BoxContainer">
        <div className="container">
          <div className="BoxText">
            <h2>Profil</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Username</InputLabel>
                <Input
                  {...register('username', {
                    pattern: {
                      value: USER_REGEX,
                      message: 'Regex Error.'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="username"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Password</InputLabel>
                <Input
                  {...register('password', {
                    pattern: {
                      value: PasswordReg,
                      message: 'Regex Error.'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Email</InputLabel>
                <Input
                  {...register('mail', {
                    pattern: {
                      value: MailReg,
                      message: 'Regex Error.'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                  }
                />
              </FormControl>
              <Button variant="contained" type="submit">
                Confirm
              </Button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
