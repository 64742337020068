import React from 'react';
import '../../App.scss';

import { Link } from 'react-router-dom';
import logo from '../../assets/Logo/logo-transparent-png.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import { openInNewTab } from './blank';

export function Footer() {
  return (
    <div className="Footer">
      <img className="logoFooter" src={logo} alt="logoKoreanTrip"></img>
      <div className="ContainerMention">
        <div className="Mention">
          <Link className="linkStyle" to="/mentionLegale">
            Mentions légales
          </Link>
          <Link className="linkStyle" to="/politiqueConfi">
            Politique de confidentialité
          </Link>
          <Link className="linkStyle" to="/mentionLegale">
            Politique des cookies
          </Link>
          {/*<Link className="linkStyle" to="/conditionUtilisation">*/}
          {/*  Condition d&#39;utilisation*/}
          {/*</Link>*/}
        </div>
        <div className="SocialIcon">
          <IconButton onClick={() => openInNewTab('https://www.instagram.com/koreantripbox/')}>
            <InstagramIcon />
          </IconButton>
          <IconButton
            onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100093430932906')}>
            <FacebookIcon />
          </IconButton>
        </div>
        <hr />
        <div className="Disclam">
          <p>Legal Notice*</p>
          <p>Privacy Policy*</p>
          <p>Cookie Preferences*</p>
          <p>Cookie Accessibility Policy*</p>
        </div>
        <div className="Right">
          <p>All Right Reserved</p>
        </div>
      </div>
    </div>
  );
}
