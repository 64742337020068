import React from 'react';
import { Footer } from '../Util/Footer';
import json from '../../assets/util/json/Legale.json';

export function MentionLegale() {
  return (
    <>
      <div className="mentionLeg">
        <h1>{json[0].Title}</h1>
        <p>{json[0].Partie1}</p>
        <p>{json[0].Partie2}</p>
        <p>{json[0].Partie3}</p>
        <p>{json[0].Partie5}</p>
        <h2>{json[0].Partie6}</h2>
        <p>{json[0].Partie7}</p>
        <p>{json[0].Partie8}</p>
        <h2>{json[0].Partie9}</h2>
        <p>{json[0].Partie10}</p>
        <h2>{json[0].Partie11}</h2>
        <p>{json[0].Partie12}</p>
        <h2>{json[0].Partie13}</h2>
        <p>{json[0].Partie14}</p>
        <p>{json[0].Partie15}</p>
      </div>
      <Footer />
    </>
  );
}
