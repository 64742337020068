import React from 'react';
import '../../App.scss';
import banneire1 from '../../assets/image/banniere/banniere1.jpg';
import banneire2 from '../../assets/image/banniere/banniere2.jpg';
import banneire3 from '../../assets/image/banniere/banniere3.jpg';
import banneire4 from '../../assets/image/banniere/banniere4.jpg';
import banneire5 from '../../assets/image/banniere/banniere5.jpg';
import banneire6 from '../../assets/image/banniere/banniere6.jpg';
import banneire7 from '../../assets/image/banniere/banniere7.jpg';
import banneire8 from '../../assets/image/banniere/banniere8.jpg';
import banneire9 from '../../assets/image/banniere/banniere9.jpg';

export function ScrollVert() {
  return (
    <div className="ScrollVert">
      <div className="imageGroup">
        <img src={banneire1} alt="Photo du scoll vertical" />
        <img src={banneire2} alt="Photo du scoll vertical" />
        <img src={banneire3} alt="Photo du scoll vertical" />
        <img src={banneire4} alt="Photo du scoll vertical" />
        <img src={banneire5} alt="Photo du scoll vertical" />
        <img src={banneire6} alt="Photo du scoll vertical" />
        <img src={banneire7} alt="Photo du scoll vertical" />
        <img src={banneire8} alt="Photo du scoll vertical" />
        <img src={banneire9} alt="Photo du scoll vertical" />
        <img src={banneire1} alt="Photo du scoll vertical" />
        <img src={banneire2} alt="Photo du scoll vertical" />
        <img src={banneire3} alt="Photo du scoll vertical" />
        <img src={banneire4} alt="Photo du scoll vertical" />
        <img src={banneire5} alt="Photo du scoll vertical" />
        <img src={banneire6} alt="Photo du scoll vertical" />
        <img src={banneire7} alt="Photo du scoll vertical" />
        <img src={banneire8} alt="Photo du scoll vertical" />
        <img src={banneire1} alt="Photo du scoll vertical" />
        <img src={banneire2} alt="Photo du scoll vertical" />
        <img src={banneire3} alt="Photo du scoll vertical" />
        <img src={banneire4} alt="Photo du scoll vertical" />
      </div>
    </div>
  );
}
