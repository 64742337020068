import React, { useState } from 'react';
import '../../App.scss';
import { Footer } from '../Util/Footer';
import { ImageFondBox } from '../Box/ImageFondBox';
import exemple2 from '../../assets/image/box/exempleProduit2.jpeg';
import exemple4 from '../../assets/image/box/exempleProduit4.jpeg';
import goodies1 from '../../assets/image/box/goodies8.jpg';
import goodies2 from '../../assets/image/box/goodies6.jpg';
import map1 from '../../assets/image/box/Map.jpg';
import map2 from '../../assets/image/box/map2.jpg';
import Image1 from '../../assets/image/box/fondBox.jpeg';
import { Box, Button, ButtonGroup, FormControl, FormLabel, Modal, Typography } from '@mui/material';
import { instance } from '../../config/axios';
import data from '../../assets/util/json/Box.json';

interface Produit {
  id: number;
  name: string;
  description: string;
  prix: number;
  ref: string;
}

export function BoxPage() {
  const [open, setOpen] = useState<boolean>(false);
  const [produit, setProduit] = useState<Array<Produit>>([
    {
      id: 1,
      name: '',
      description: '',
      prix: 0,
      ref: ''
    }
  ]);
  const [select, setSelect] = useState<number>(0);
  const [selectedBtn, setSelectedBtn] = useState(1);
  const handleOpen = async () => {
    setOpen(true);
    await instance
      .get('produit/getAll')
      .then((res) => {
        setProduit(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => setOpen(false);

  const handleChange = (num: number) => {
    setSelect(-1 + num);
    setSelectedBtn(num);
  };

  const goPay = async () => {
    console.log(produit[select].ref);
    const body = {
      article: produit[select].ref
    };

    const headers = {};

    instance
      .post('/stripe/create-checkout-session', body, headers)
      .then((res) => {
        console.log('stripe', res.data);
        window.open(res.data, '_blank', 'noreferrer');
      })
      .catch((err) => console.log(err));
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid rgb(7, 107, 178)',
    borderRadius: '45px',
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <ImageFondBox />
      <div className="imageFondDefault">
        <div className="BoxContainer">
          {/*<div className="sectionText">*/}
          {/*  <h2 className="title">Inclus</h2>*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      <p>La Box</p>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <p>Les Goodies</p>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <p>Une Carte Interactive</p>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*  <div>*/}
          {/*    <h2>Detail</h2>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="container">
            <div className="BoxText">
              <h2>{data.Container1Title}</h2>
              <p>{data.Container1Texte}</p>
            </div>
            <div className="Box">
              <img src={exemple4} alt="img1" className="img1" />
              <img src={exemple2} alt="img2" className="img2" />
            </div>
          </div>
          <div className="containerRight">
            <div className="Box">
              <img src={map1} alt="img1" className="img1" />
              <img src={map2} alt="img2" className="img2" />
            </div>
            <div className="BoxText">
              <h2>{data.Container2Title}</h2>
              <p>{data.Container2Texte}</p>
            </div>
          </div>
          <div className="container">
            <div className="BoxText">
              <h2>{data.Container3Title}</h2>
              <p>{data.Container3Texte}</p>
            </div>
            <div className="Box">
              <img src={goodies1} alt="img1" className="img1" />
              <img src={goodies2} alt="img2" className="img2" />
            </div>
          </div>
          <div className="containerButton">
            <Button onClick={handleOpen}>See Detail</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box className="ModalBox" sx={style}>
                <Box className="ModalImg">
                  <img src={Image1} alt="img2" />
                </Box>
                <Box className="modalContainer">
                  <FormControl id="modal-modal-description" sx={{ mt: 2 }}>
                    <Typography
                      className="title"
                      id="modal-modal-title"
                      variant="h6"
                      component="h2">
                      {produit[select].name}
                    </Typography>
                    <Box>
                      Description : <br />
                      {data.ModelTexte}
                      <br />
                      {produit[select].description}
                    </Box>
                    <Box>Prix : {produit[select].prix} €</Box>
                    <FormLabel id="demo-row-radio-buttons-group-label">Box Version</FormLabel>
                    <ButtonGroup sx={{ mt: 2 }} size="large" aria-label="large button group">
                      <Button
                        color={selectedBtn === 1 ? 'secondary' : 'primary'}
                        onClick={() => handleChange(1)}
                        key="one">
                        Dématérialisé
                      </Button>
                      <Button
                        color={selectedBtn === 2 ? 'secondary' : 'primary'}
                        onClick={() => handleChange(2)}
                        key="two">
                        Physique
                      </Button>
                      <Button
                        color={selectedBtn === 3 ? 'secondary' : 'primary'}
                        onClick={() => handleChange(3)}
                        key="three">
                        Map
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup sx={{ mt: 2 }} size="large" aria-label="large button group">
                      <Button
                        color={selectedBtn === 4 ? 'secondary' : 'primary'}
                        onClick={() => handleChange(4)}
                        key="four">
                        Bundle
                      </Button>
                      <Button
                        color={selectedBtn === 5 ? 'secondary' : 'primary'}
                        onClick={() => handleChange(5)}
                        key="five">
                        Bundle Dématérialisé
                      </Button>
                    </ButtonGroup>
                    <Button sx={{ mt: 2 }} variant="contained" onClick={goPay}>
                      Buy Now
                    </Button>
                  </FormControl>
                </Box>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
