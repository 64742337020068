import React from 'react';
import { Footer } from '../Util/Footer';

export function CondiUtil() {
  return (
    <>
      <div className="mentionLeg">
        <h1>Condition d Utilisation</h1>
      </div>
      <Footer />
    </>
  );
}
