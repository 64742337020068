import React from 'react';
import Input from '@mui/material/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, FormControl, InputLabel } from '@mui/material';
import { MailReg, PasswordReg, USER_REGEX } from '../../assets/util/RegExp';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Register {
  username: string;
  age: number;
  email: string;
  password: string;
  Conf_password: string;
}

const url = 'http://localhost:8080/auth/register';

export function RegisterForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: '',
      email: '',
      password: '',
      Conf_password: ''
    },
    criteriaMode: 'all'
  });

  const onSubmit: SubmitHandler<Register> = (data) => {
    console.log(data);
    if (data && data.password === data.Conf_password && data.password !== '') {
      console.log('Form OK');
      console.log('data', data);
      axios
        .post(url, {
          username: data.username,
          mail: data.email,
          password: data.password
        })
        .then((data) => {
          console.log('data', data);
          navigate('/login');
        })
        .catch((err) => console.log('err', err));
    } else {
      console.log('Password Invalide');
    }
  };

  return (
    <div className="AuthBox">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Username</InputLabel>
          <Input
            {...register('username', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: USER_REGEX,
                message: 'Regex Error.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="username"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Email</InputLabel>
          <Input
            {...register('email', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: MailReg,
                message: 'This input need a e-mail.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Password</InputLabel>
          <Input
            {...register('password', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: PasswordReg,
                message: 'This input need a letter uppercase and a number.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel size="normal">Confirm Password</InputLabel>
          <Input
            {...register('Conf_password', {
              required: {
                value: true,
                message: 'This input is required.'
              },
              pattern: {
                value: PasswordReg,
                message: 'This input need a letter uppercase and a number.'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="Conf_password"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
            }
          />
        </FormControl>
        <Button variant="contained" type="submit">
          Register
        </Button>
      </form>
    </div>
  );
}
