import React from 'react';
import '../../App.scss';
import logo from '../../assets/Logo/logo-png.png';
import { ScrollVert } from '../Home/ScrollVert';
import { Social } from '../Home/Social';
import { Contact } from '../Home/Contact';
import { Footer } from '../Util/Footer';
import { Card, CardContent, Typography } from '@mui/material';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AlertDialogSlide from '../Util/Dialog';
import json from '../../assets/util/json/Dialog.json';

// import { Partenaire } from '../Home/Partenaire';

export function HomePage() {
  return (
    <div className="ContainerHome">
      <AlertDialogSlide Text={json[0].Text} Title={json[0].Title} isOpen={true} />
      <img className="logoBackground" src={logo} alt="logoKoreanTrip" />
      <div className="imageGarde">
        <h2>KoreanTripBox</h2>
        <h3>Le Raod Trip dont vous rêviez</h3>
      </div>
      {/*<Partenaire />*/}
      <div className="BoxPre">
        <Card sx={{ minWidth: 275, Height: 200, borderRadius: 10, boxShadow: 10 }}>
          <CardContent>
            <LocalShippingIcon fontSize="large" sx={{ marginBottom: 2 }} />
            <Typography sx={{ marginBottom: 2 }} variant="h5" component="div">
              Livraison Rapide
            </Typography>
            <Typography variant="body2">
              La livraison est effectuée
              <br />
              par un service fiable et sécurisé
              <br />
              livrable dans toute la France.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, Height: 200, borderRadius: 10, boxShadow: 10 }}>
          <CardContent>
            <LaptopChromebookIcon fontSize="large" sx={{ marginBottom: 2 }} />
            <Typography sx={{ marginBottom: 4 }} variant="h5" component="div">
              Mise à jour
            </Typography>
            <Typography variant="body2">
              Notre service est constamment mise à jour,
              <br />
              afin de vous offrir le meilleur.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, Height: 200, borderRadius: 10, boxShadow: 10 }}>
          <CardContent>
            <EuroSymbolIcon fontSize="large" sx={{ marginBottom: 2 }} />
            <Typography sx={{ marginBottom: 4 }} variant="h5" component="div">
              Remboursement
            </Typography>
            <Typography variant="body2">
              Satisfait ou Remboursé
              <br />
              dans les 30 jours à réception de la commande.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, Height: 200, borderRadius: 10, boxShadow: 10 }}>
          <CardContent>
            <AutoStoriesIcon fontSize="large" sx={{ marginBottom: 2 }} />
            <Typography sx={{ marginBottom: 2 }} variant="h5" component="div">
              Impression
            </Typography>
            <Typography variant="body2">
              Nous proposons aussi un service
              <br />
              d&#39;impression qui facilitera
              <br />
              votre voyage.
            </Typography>
          </CardContent>
        </Card>
      </div>
      {/*<div className="InstaBox">*/}
      {/*  */}
      {/*</div>*/}
      <Social />
      <ScrollVert />
      <Contact />
      <Footer />
    </div>
  );
}
